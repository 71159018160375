import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

firebase.initializeApp({
    apiKey: "AIzaSyDincxMP4erbuqOm29q5kg1VUuVdKT1F78",
    authDomain: "proyecto-equipos-de-elevacion.firebaseapp.com",
    databaseURL: "https://proyecto-equipos-de-elevacion.firebaseio.com",
    projectId: "proyecto-equipos-de-elevacion",
    storageBucket: "proyecto-equipos-de-elevacion.appspot.com",
    messagingSenderId: "695873998200",
    appId: "1:695873998200:web:f9f2a9738347555ac18c8d",
    measurementId: "G-E25B3SC0ZL"
});

export const firestore = firebase.firestore();
export const database = firebase.database();
export const auth = firebase.auth();
export const storage = firebase.storage();