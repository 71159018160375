import React, { Component } from 'react';
import { firestore } from '../../firebase';
import _ from 'lodash';

import ProductsItem from './ProductsItem';

class Products extends Component {
    constructor() {
        super();
        this.state = {
            products: undefined,
            categories: undefined,
            selectedCategory: undefined
        }
    }
    componentDidMount() {
        window.scrollTo(0,0);
        firestore.collection('productsAndServices').where('brandId', '==', 'brand-1552021135874').where('status','==','active').get().then(snap => {
            this.setState({
                products: snap.docs.map(doc => doc.data())
            });
        });
        firestore.collection('categories').where('isPronal', '==', 'true').get().then(snap => {
            this.setState({
                categories: snap.docs.map(doc => doc.data())
            })
        })
    }
    renderCategory(key,list) {
        const item = list[key];
        return (
            <li key={key} onClick={() => this.setState({ selectedCategory: item.id })} className={this.state.selectedCategory === item.id ? 'active' : ''}>{item.name}</li>
        )
    }
    categoriesValidator(list) {
        if (list) {
            return Object.keys(list).map(key => this.renderCategory(key,list))
        }
    }
    validator(list) {
        if (list) {
            return Object.keys(list).map(key => <ProductsItem
                key={key}
                item={list[key]}
            />)
        }
    }
    render() {
        const products = this.state.selectedCategory ? _.filter(this.state.products, o => {
            if (o.categories) {
                return o.categories[this.state.selectedCategory] === true
            }
        }) : this.state.products;
        return (
            <div className="products">
                <div className="products__container">
                    <ul className="products__categories">
                        <li className={this.state.selectedCategory === undefined ? 'active' : ''} onClick={() => this.setState({ selectedCategory: undefined })}>Todos los productos</li>
                        {this.categoriesValidator(this.state.categories)}
                    </ul>
                    <div className="products__content">
                        {this.validator(products)}
                    </div>
                </div>
            </div>
        )
    }
}

export default Products;