import  React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

class ProductsItem extends Component {
    render() {
        const {item} = this.props;
        const images = _.orderBy(item.images)
        const image = images[0] ? images[0] : {};
        const description = item.description.substring(0,130);
        return (
            <Link to={`/productos/${item.id}`} className="products__item">
                <picture>
                    <img src={image.file} alt={image.description}/>
                </picture>
                <h3>{item.name}</h3>
                <p>{description}...</p>
            </Link>
        )
    }
}

export default ProductsItem;