import React, { Component } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import moment from 'moment';

import Home from './home/Home';
import { database } from '../firebase';
import About from './about/About';
import Products from './products/Products';
import Contact from './contact/Contact';
import SingleProduct from './single-product/SingleProduct';

class App extends Component {
    handleSubmit(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const email = this.email.value;
        
        if (email) {
            const mail = {
                email,
                timestamp,
                page: 'PRONAL'
            }
            const mailId = `newsSubscription-${timestamp}`;
            database.ref('newsSubscriptions').child(mailId).set(mail);
            alert('Suscripción realizada');
            this.addForm.reset();
        } else {
            alert('Escribe un email para continuar')
        }
    }
    render() {
        return (
            <div className="app">
                <header>
                    <div className="header__pages">
                        <div className="header__pages-container">
                            <a href="https://equiposdeelevacion.com.mx/" target="_blank">
                                <img style={{ height: '1.5rem' }} src="/images/equipos.png" alt="Equipos de Elevación"/>
                            </a>
                            <a href="https://polipastosqueretaro.mx/" target="_blank">
                                <img style={{ height: '1.5rem' }} src="/images/pnq.png" alt="Polipastos Querétaro"/>
                            </a>
                        </div>
                    </div>
                    <div className="header__container">
                        <Link to="/">
                            <img src="/images/logo-e.png" alt="PRONAL México"/>
                        </Link>
                        <ul>
                            <Link to="/"><span>Inicio</span></Link>
                            <Link to="/nosotros"><span>Nosotros</span></Link>
                            <Link to="/productos"><span>Productos</span></Link>
                            <Link to="/contacto"><span>Contacto</span></Link>
                            <a target="_blank" rel="noopener noreferrer" href="https://equiposdeelevacion.com.mx/"><span>Equipos de Elevación</span></a>
                        </ul>
                    </div>
                </header>
                <main>
                    <Switch>
                        <Route path="/" component={Home} exact={true} />
                        <Route path="/nosotros" component={About} exact={true} />
                        <Route path="/productos" component={Products} exact={true} />
                        <Route path="/contacto" component={Contact} exact={true} />
                        <Route path="/productos/:productId" component={SingleProduct} exact={true} />
                    </Switch>
                </main>
                <section className="footer">
                    <div className="footer__container">
                        <ul>
                            <Link to="/">Inicio</Link>
                            <Link to="/nosotros">Nosotros</Link>
                            <Link to="/productos">Productos</Link>
                            <Link to="/contacto">Contacto</Link>
                        </ul>
                        <ul className="fl-2">
                            <li>
                                <b>Teléfonos</b>
                                <ul>
                                    <li>+52 (55) 5397 4949</li>
                                    <li>+52 (55) 5365 5113</li>
                                    <li>+52 (55) 5365 5115</li>
                                    <li>+52 (55) 5361 1070</li>
                                </ul>
                            </li>
                            <li>
                                <b>Dirección</b>
                                <span>Av. Gustavo Baz No. 301, Colonia Viveros de la Loma, Tlalnepantla. Estado de México. CP 54080</span>
                            </li>
                            <li>
                                <b>Correo Electrónico</b>
                                <span><a href="mailto:administracion@equiposdeelevacion.com.mx">administracion@equiposdeelevacion.com.mx</a></span>
                            </li>
                        </ul>
                        <form ref={(input) => this.addForm = input} onSubmit={(e) => this.handleSubmit(e)}>
                            <span>Mantente informado de nuestras novedades</span>
                            <div className="footer__input-container">
                                <input ref={(input) => this.email = input} placeholder="ESCRIBE TU EMAIL" type="email"/>
                                <button type="submit">Suscribirme</button>
                            </div>
                        </form>
                    </div>
                    <div className="footer__divider">
                        <ul className="footer__container">
                            <li>
                                Equipos de Elevación SA de CV | Todos los derechos reservados © 2018 | Sitio desarrollado por <a target="_blank" href="https://www.thebusinessyoung.com/" rel="noopener noreferrer">TBY</a>
                            </li>
                            <li>
                                <a target="_blank" rel="noopener noreferrer" href="https://equiposdeelevacion.com.mx/admin">Portal Equipos de Elevación</a>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        )
    }
}

export default App;