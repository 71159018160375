import React, { Component } from 'react';
import { firestore } from '../../firebase';
import Modal from 'react-modal';
import moment from 'moment';

class SingleProductModal extends Component {
    componentDidMount() {
        Modal.setAppElement('body');
    }
    handleSubmit(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const name = this.name.value;
        const email = this.email.value;
        const phone = this.phone.value;

        if (name && email) {
            const infoRequestId = `infoRequest-${timestamp}`;
            const infoRequest = {
                name,
                email,
                phone,
                timestamp,
                id: infoRequestId,
                page: "Pronal",
                status: 'noRead',
                pasId: this.props.pasId
            }
            firestore.collection('info-requests').doc(infoRequestId).set(infoRequest).then(() => {
                alert('Solicitud de información enviada');
                this.props.closeModal()
            }).catch(error => {
                alert(error.message)
            })
        } else {
            alert('Completa tu nombre y correo electrónico')
        }
    }
    render() {
        const customStyles = {
            overlay: {
                zIndex: 3
            },
            content: {
                top:'0px',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, 0%)',
                borderRadius: '0px',
                backgroundColor: '#fff',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none'
            }
        };
        return (
            <Modal
                isOpen={!!this.props.modalOpen}
                onRequestClose={this.props.closeModal}
                contentLabel='Solicitud de Información'
                style={customStyles}
            >
                <div className="single-product__modal">
                    <form onSubmit={e => this.handleSubmit(e)}>
                        <div className="input-container">
                            <label>Nombre (Obligatorio)</label>
                            <input placeholder="Nombre" ref={input => this.name = input} type="text"/>
                        </div>
                        <div className="input-container">
                            <label>Correo Electrónico (Obligatorio)</label>
                            <input placeholder="Nombre" ref={input => this.email = input} type="email"/>
                        </div>
                        <div className="input-container">
                            <label>Teléfono</label>
                            <input placeholder="Teléfono" ref={input => this.phone = input} type="text"/>
                        </div>
                        <div className="input-container">
                            <button type="submit">Solicitar Información</button>
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}

export default SingleProductModal;