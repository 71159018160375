import React, { Component } from 'react';
import { firestore } from '../../firebase';
import { Link } from 'react-router-dom';
import moment from 'moment';

class Home extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    sendMessage(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const name = this.name.value;
        const email = this.email.value;
        const phone = this.phone.value;
        const message = this.message.value;
        const status = 'noRead';
        const messageId = `message-${timestamp}`;
        if (name && email && message) {
            const contact = {
                name,
                email,
                phone,
                message,
                status,
                timestamp,
                page: 'PRONAL',
                id: messageId
            }
            firestore.collection('messages').doc(messageId).set(contact).then(() => {
                alert('Mensaje enviado, un asesor se pondrá en contacto contigo');
                this.contactForm.reset();
            }).catch(error => {
                alert(error.message)
            })
        } else {
            alert('Completa todos los campos solicitados')
        }
    }
    render() {
        return (
            <div className="home">
                <div className="home__cover">
                    <ul>
                        <Link to="/productos">
                            <h3>Industria <i className="material-icons">keyboard_arrow_right</i></h3>
                        </Link>
                        <Link to="/productos">
                            <h3>Talleres Móviles <i className="material-icons">keyboard_arrow_right</i></h3>
                        </Link>
                        <Link to="/productos">
                            <h3>Petroleo <i className="material-icons">keyboard_arrow_right</i></h3>
                        </Link>
                    </ul>
                    <ul>
                        <Link to="/productos">
                            <h3>Construcción <i className="material-icons">keyboard_arrow_right</i></h3>
                        </Link>
                        <Link to="/productos">
                            <h3>Medio Ambiente <i className="material-icons">keyboard_arrow_right</i></h3>
                        </Link>
                        <Link to="/productos">
                            <h3>Mantenimiento Industrial <i className="material-icons">keyboard_arrow_right</i></h3>
                        </Link>
                    </ul>
                </div>
                <div className="home__first">
                    <div className="home__first-container">
                        <h1>PRONAL <small>por <b>Equipos de Elevación</b></small></h1>
                        <p>Soluciones para Izaje de equipos de trabajo y transportación, Obturadores para tuberías <br/>
                        y Cisternas flexibles para almacenaje de agua e hidrocarburos, Marca PRONAL. <br/>
                        Representante en México: Equipos de Elevación, S.A. de C.V.</p>
                    </div>
                </div>
                <div className="home__second">
                    <div className="home__second-container">
                        <h2>Acerca de Nosotros</h2>
                        <p>Somos el representante de ventas por parte de PRONAL en México. Ingresa a <a href="https://equiposdeelevacion.com.mx">Equipos de Elevación</a> para conocer más de nosotros. Más de 20 años de experiencia en el mercado, técnicos especializados, equipo de ventas de primer nivel listo para asesorarle antes y después de su compra.</p>
                    </div>
                </div>
                <div className="home__three">
                    <div className="home__three-container">
                        <h2>Nuestros Clientes</h2>
                        <ul>
                            <li><img src="/images/customers/3b-logo.png" alt="3B"/></li>
                            <li><img src="/images/customers/a-shulmal-logo.png" alt="A Shulmal"/></li>
                            <li><img src="/images/customers/alstom-logo.png" alt="Alstom"/></li>
                            <li><img src="/images/customers/alutech-logo.png" alt="Alutech"/></li>
                            <li><img src="/images/customers/bayer-logo.png" alt="Bayer"/></li>
                            <li><img src="/images/customers/braskem-logo.png" alt="Braskem"/></li>
                            <li><img src="/images/customers/cassini-logo.png" alt="Cassini"/></li>
                            <li><img src="/images/customers/Coppel-logo.png" alt="Coppel"/></li>
                            <li><img src="/images/customers/Logo_comex.png" alt="Comex"/></li>
                            <li><img src="/images/customers/Maire_Tecnimont.png" alt="Maire Tecnimont"/></li>
                            <li><img src="/images/customers/neto-logo.png" alt="Neto"/></li>
                            <li><img src="/images/customers/saint-logo.png" alt="Saint Logo"/></li>
                       </ul>
                    </div> 
                </div>
                <section className="home__contact">
                    <div className="home__contact-container">
                        {/* <h3>Recibe más información de nuestros productos y servicios<br/>para equipos de elevación y polipastos.</h3> */}
                        <div className="home__contact-middle-container">
                            <form onSubmit={(e) => this.sendMessage(e)} ref={(input) => this.contactForm = (input)} >
                                <div className="home__input-container">
                                    <label>Nombre</label>
                                    <input type="text" ref={(input) => this.name = input} />
                                </div>
                                <div className="home__input-container">
                                    <label>Correo Electrónico</label>
                                    <input type="email" ref={(input) => this.email = input} />
                                </div>
                                <div className="home__input-container">
                                    <label>Teléfono (Opcional)</label>
                                    <input type="text" ref={(input) => this.phone = input} />
                                </div>
                                <div className="home__input-container">
                                    <label>Mensaje</label>
                                    <textarea ref={(input) => this.message = input}></textarea>
                                </div>
                                <button type="submit">Enviar</button>
                            </form>
                        </div>
                        <div className="home__contact-middle-container">
                            <h4>Contáctanos</h4>
                            <span className="home__contact-phone"><i className="material-icons">phone</i>+52 (55) 5365 5115</span>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Home;