import React, { Component } from 'react';

class About extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        return (
            <div className="about">
                <div className="about__cover">
                    <h1>Acerca de PRONAL <br/><small>por Equipos de Elevación</small></h1>
                </div>
                <div className="about__content">
                    <div className="about__content-container">
                        <h3>Nuestra Empresa</h3>
                        <p>Entre nuestros valores podemos destacar un alineamiento total con las necesidades de nuestros clientes, lo que hace que busquemos ofrecerles mejores soluciones cada día algo que supone una ventaja competitiva. En los más de 20 años años que llevamos en el mercado, hemos trabajado con clientes como Tecnimont, Technip, Braskem Idesa y más corporaciones de primer nivel.</p>
                        <h3>Nosotros</h3>
                        <p>Dentro del programa de soluciones para industrias, nos encontramos con tres líneas a través de las cuales usted puede mejorar la eficiencia de izaje, obturación de tuberías y Cisternas flexibles para almacenamiento de fluídos. La primera de ellas tiene que ver con Cojines de levantamiento para la mejora en el servicio técnico de sus equipos de trabajo y transportación. La segunda solución son los obturadores para el mantenimiento de tuberías de PVC y Concreto. La última solución es para el almacenaje de agua e hidrocarburos.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;