import React, { Component } from 'react';
import { firestore } from '../../firebase';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SingleProductModal from './SingleProductModal';

class SingleProduct extends Component {
    constructor() {
        super();
        this.handleModal = this.handleModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.state = {
            product: {},
            modalOpen: undefined
        }
    }
    componentDidMount() {
        firestore.collection('productsAndServices').doc(this.props.match.params.productId).get().then(snap => {
            this.setState({
                product: snap.data()
            });
        });
    }
    handleModal() {
        this.setState({
            modalOpen: true
        });
    }
    closeModal() {
        this.setState({
            modalOpen: undefined
        });
    }
    renderItem(key, list) {
        const item = list[key];
        return (
            <div key={key} className="single-product__image">
                <img src={item.file} alt={item.description}/>
            </div>
        )
    }
    imageValidator(list) {
        if (list) {
            return Object.keys(list).map(key => this.renderItem(key, list))
        }
    }
    renderInclude(key,list) {
        const item = list[key];
        return (
            <li key={key}>{item.text}</li>
        )
    }
    includesValidator(list) {
        if (list) {
            return Object.keys(list).map(key => this.renderInclude(key, list))
        }
    }
    render() {
        const { product } = this.state;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        const pasId = this.props.match.params.productId;
        return (
            <div className="single-product">
                <div className="single-product__container">
                    <div className="single-product__carousel">
                        <Slider {...settings}>
                            {this.imageValidator(product.images)}
                        </Slider>
                    </div>
                    <div className="single-product__content">
                        <h1>{product.name}</h1>
                        <span>Modelo: {product.model}</span>
                        <button onClick={this.handleModal}>Solicitar información de este producto</button>
                        <h3>Descripción</h3>
                        <p>{product.description}</p>
                        {product.includes ? <h3>Detalles</h3> : ''}
                        <ul>{this.includesValidator(product.includes)}</ul>
                        <h3>Tiempo de Entrega</h3>
                        <p>{product.deliveryTime}</p>
                        <h3>Garantía</h3>
                        <p>{product.warranty}</p>
                    </div>
                </div>
                <SingleProductModal
                    modalOpen={this.state.modalOpen}
                    closeModal={this.closeModal}
                    pasId={pasId}
                />
            </div>
        )
    }
}

export default SingleProduct;