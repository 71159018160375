import React, { Component } from 'react';
import { firestore } from '../../firebase';
import moment from 'moment';

class Contact extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    sendMessage(e) {
        e.preventDefault();
        const timestamp = moment().valueOf();
        const name = this.name.value;
        const email = this.email.value;
        const phone = this.phone.value;
        const message = this.message.value;
        const status = 'noRead';
        const messageId = `message-${timestamp}`;
        if (name && email && message) {
            const contact = {
                name,
                email,
                phone,
                message,
                status,
                timestamp,
                page: 'PRONAL',
                id: messageId
            }
            firestore.collection('messages').doc(messageId).set(contact).then(() => {
                alert('Mensaje enviado, un asesor se pondrá en contacto contigo');
                this.addForm.reset();
            }).catch(error => {
                alert(error.message)
            });
        } else {
            alert('Completa todos los campos solicitados')
        }
    }
    render() {
        return (
            <div className="contact">
                <div className="contact__bg">
                
                </div>
                <div className="contact__form">
                    <form onSubmit={e => this.sendMessage(e)} ref={input => this.addForm = input}>
                        <div className="input-container">
                            <label>Nombre</label>
                            <input placeholder="Nombre" ref={input => this.name = input} type="text"/>
                        </div>
                        <div className="input-container">
                            <label>Email</label>
                            <input placeholder="Email" ref={input => this.email = input} type="email"/>
                        </div>
                        <div className="input-container">
                            <label>Teléfono</label>
                            <input placeholder="Teléfono" ref={input => this.phone = input} type="text"/>
                        </div>
                        <div className="input-container">
                            <label>Mensaje</label>
                            <textarea placeholder="Mensaje" ref={input => this.message = input}/>
                        </div>
                        <div className="input-container">
                            <button type="submit">Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default Contact;